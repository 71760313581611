<template>
  <div class="box-wrap">
    <div class="tab-box">
      <van-tabs
        v-model="active"
        sticky
        swipeable
        animated
        title-active-color="#FF9D20"
        color="#FF9D20"
        @change="beforeChange"
      >
        <van-tab
          v-for="(tab, index) of tabList"
          :key="index"
          :title="tab.title"
        >
          <template v-if="zxList.length > 0">
            <div
              class="tab-item zx-item"
              @click="goDetail(zx)"
              v-for="zx of zxList"
              :key="zx.id"
            >
              <div class="zx-left">
                <h3 class="zx-title two-line">{{ zx.title }}</h3>
                <div class="zx-info">
                  <p>
                    <img src="@/assets/img/homerec/zj.png" alt="" />{{
                      zx.createTime | setTime
                    }}
                  </p>
                  <img
                    class="see-img"
                    src="@/assets/img/homerec/see.png"
                    alt=""
                  />
                  <span class="number-css">{{
                    Number(zx.realReadAmount || 0) +
                    Number(zx.baseReadAmount || 0)
                  }}</span>
                </div>
                <div class="zx-tag">
                  <span
                    v-for="(tag, tagIndex) of zx.categorys"
                    :key="'tag_' + tagIndex"
                  >
                    {{ tag }}
                  </span>
                </div>
              </div>
              <div class="zx-right">
                <img :src="zx.coverImages[0]" alt="" />
              </div>
            </div>
            <Nomore />
          </template>
          <van-empty v-else description="当前分类下暂无资讯信息" />
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import { getSeeListApi } from "@/api/apiList.js";
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "infoList",
  filters: {
    setTime(time) {
      let str = time.slice(0, 10);
      return str;
    },
  },
  components: {
    Nomore: () => import("@/components/nomore/index.vue"),
  },
  data() {
    return {
      active: 0,
      tabList: [
        {
          title: "推荐",
          code: "",
        },
        {
          title: "选墓攻略",
          code: "选墓攻略",
        },
        {
          title: "陵园解答",
          code: "陵园解答",
        },
        {
          title: "殡葬常识",
          code: "殡葬常识",
        },
        {
          title: "文化习俗",
          code: "文化习俗",
        },
        {
          title: "行业动态",
          code: "行业动态",
        }
      ],
      zxList: [],
    };
  },
  computed: {
    ...mapGetters(["cityCode", "pages"]),
  },
  created() {
    this.setCityCode(this.$route.query.citycode || 1101);
    this.getSeeListFun();
  },
  methods: {
    ...mapMutations(["setCityCode"]),
    getSeeListFun() {
      return new Promise((resolve) => {
        getSeeListApi({
          cityCode: this.cityCode,
          current: 1,
          size: 999,
          category: this.tabList[this.active].code,
        })
          .then((res) => {
            this.zxList = res.data.data?.records || [];
            console.log(this.zxList, "zxList");
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    },
    beforeChange() {
      let toast = this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      this.zxList = [];
      this.getSeeListFun().then(() => {
        toast.clear();
      });
    },
    goDetail(item) {
      if (this.pages.channel && this.pages.subChannel) {
        this.$router.push(
          `/rec/infodetail/${this.pages.channel}/${this.pages.subChannel}?id=${item.id}`
        );
      } else {
        this.$router.push(`/rec/infodetail?id=${item.id}`);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.box-wrap::v-deep {
  background: #f7f7f7;
  .tab-box {
    padding: 0.2rem 0.3rem 0;
    background: #fff;
    .van-tabs__content {
      width: 7.5rem;
      margin-top: 0.1rem;
      margin-left: -0.3rem;
      padding: 0.2rem;
      background: #f7f7f7;
      box-sizing: border-box;
    }
    .van-tabs__nav--line {
      padding-bottom: .18rem;
    }
    .van-tabs__line {
      width: .4rem;
    }
    .van-tab  {
      font-size: .32rem;
    }
    .zx-item {
      padding: 0.2rem;
      background: #fff;
      border-radius: 0.12rem;
      margin-bottom: 0.2rem;
      padding-bottom: 0;
      display: flex;
      justify-content: space-between;
      .zx-left {
        .zx-title {
          font-size: 0.32rem;
          font-family: Alibaba PuHuiTi 2-65 Medium, Alibaba PuHuiTi 20;
          font-weight: 500;
          color: #333333;
          line-height: 0.38rem;
          height: 0.76rem;
        }
        .zx-info {
          display: flex;
          align-items: center;
          padding-top: 0.12rem;
          p {
            display: flex;
            align-items: center;
            font-size: 0.24rem;
            font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
            font-weight: 400;
            color: #999999;
            line-height: 0.38rem;
            img {
              width: 0.18rem;
              height: 0.18rem;
              transform: translateY(-0.02rem);
              margin-right: 0.04rem;
            }
          }
          .see-img {
            width: 0.26rem;
            margin-left: 0.56rem;
          }
          .number-css {
            font-size: 0.24rem;
            font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
            font-weight: 400;
            color: #999999;
            line-height: 0.38rem;
            padding-left: 0.04rem;
          }
        }
        .zx-tag {
          display: flex;
          flex-wrap: wrap;
          padding-top: 0.12rem;

          span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.2rem;
            height: 0.4rem;
            border-radius: 0.08rem;
            border: 1px solid #ffb251;
            font-size: 0.24rem;
            font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
            font-weight: 400;
            color: #ff9d20;
            line-height: 1;
            margin-right: 0.12rem;
            margin-bottom: 0.12rem;
          }
        }
      }
      .zx-right {
        width: 2.54rem;
        height: 1.78rem;
        padding-left: 0.2rem;
        flex: none;
        img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 0.1rem;
        }
      }
    }
  }
}
</style>
